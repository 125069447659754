<template src="./details.html"></template>

<script>
import Http from '@/shared/http-config'
import moment from 'moment'
import {VueCroppieComponent} from 'vue-croppie'

export default {
  name: "Details",
  components: {'vue-croppie': VueCroppieComponent},
  data: function () {
    return {
      password: null,
      password_confirm: null,
      new_picture: null,
      user: {
        address: {}
      },
      original_user: {}
    }
  },
  mounted: function(){
    this.$store.dispatch('setIntroJsEnabled', false);
  },
  destroyed: function(){
    this.$store.dispatch('setIntroJsEnabled', true);
  },
  created: function(){
    let self = this;
    Http.request('GET', '/user').then((user) => {
      self.user = user.data;
      self.user.birth_date = moment(self.user.birth_date).format('YYYY-MM-DD');
      if(!self.user.address){
        self.user.address = {};
      }
      self.original_user = JSON.parse(JSON.stringify(user.data));
    });
  },
  methods: {
    onFileChange(e) {
      this.new_picture = e.target.files[0];
      this.$bvModal.show('picture-crop-modal');
    },
    setCropImage(){
      let imgPreview = URL.createObjectURL(this.new_picture);
      this.$refs.croppieRef.bind({
        url: imgPreview,
        zoom: 0
      });
    },
    /* Todo: find solution for croppieRef in unit test */
    /* istanbul ignore next */
    setProfilePicture(){
      let self = this;
      let options = {
        type: 'blob',
        size: { width: 1024, height: 1024 },
        format: 'jpeg'
      };
      self.$refs.croppieRef.result(options, output => {
        self.new_picture = output;
        self.user.picture = URL.createObjectURL(self.new_picture);
        self.$bvModal.hide('picture-crop-modal');
      });
    },
    /* Todo: find solution for croppieRef in unit test */
    /* istanbul ignore next */
    rotate(rotationAngle) {
      this.$refs.croppieRef.rotate(rotationAngle);
    },
    save(){
      let self = this;
      let formData = new FormData();
      if(self.new_picture !== null) {
        formData.append('new_picture', self.new_picture);
      }
      if(self.password !== null) {
        formData.append('password', self.password);
      }
      formData.append('first_name', self.user.first_name);
      formData.append('last_name', self.user.last_name);
      formData.append('email', self.user.email);
      formData.append('birth_date', self.user.birth_date);
      formData.append('birth_city', self.user.birth_city);
      formData.append('phone', self.user.phone);
      formData.append('mobile', self.user.mobile);
      formData.append('address[street]', self.user.address.street);
      formData.append('address[city]', self.user.address.city);
      formData.append('address[zip]', self.user.address.zip);

      Http.request('POST', '/user', formData).then((user) => {
        user.data.birth_date = moment(user.data.birth_date).format('YYYY-MM-DD');
        self.user = user.data;
        if(self.password !== null) {
          self.password = null;
          self.password_confirm = null;
        }
        if(self.original_user.email !== self.user.email) {
          self.$auth.logOut();
        }
        else {
          self.$bvToast.toast('Die Änderungen wurden gespeichert', {
            title: 'Gespeichert',
            variant: 'success',
            autoHideDelay: 5000
          })
        }
      });
    },
  }
}
</script>

<style scoped></style>
